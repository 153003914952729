import React from "react"
import Fade from "react-reveal/Fade"

import about2 from "../../images/about2.webp"
import about3 from "../../images/about3.webp"

const AboutMore = () => {
  return (
    <div className="max-w-7xl mx-auto mt-10 px-4 sm:px-6 lg:px-8">
      <Fade bottom cascade>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="sm:col-span-2 h-96 overflow-hidden rounded-xl">
            <img
              className="object-cover h-96 w-full"
              src={about2}
              alt="about image 2"
            ></img>
          </div>
          <div className="h-96 overflow-hidden rounded-xl">
            <img
              className="object-cover h-96 w-full"
              src={about3}
              alt="about image 3"
            ></img>
          </div>
        </div>
      </Fade>
      <div className="grid grid-cols-1 sm:grid-cols-4">
        <p className="text-white text-lg sm:text-3xl leading-normal mt-10 sm:col-start-3 sm:col-end-5">
          Orangethink drives the evolution of cross-border e-commerce and global content platforms through continuous technological innovation. We have always stayed at the forefront, from the early days of book printing to modern electronic typesetting. Orangethink remains committed to delivering the best experience to our users. Technology may change, but our promise remains the same.
        </p>
      </div>
    </div>
  )
}

export default AboutMore
