import React from "react"

import founder from "../../images/about-founder.webp"

const AboutTeamBigCard = () => {
  return (
    <div className="max-w-7xl mx-auto mt-10">
      <div className="bg-lightblack w-full h-full flex flex-row justify-between rounded-xl xxs:flex-col sm:flex-row">
        <img
          className="w-3/4 object-cover h-96 rounded-l-lg xxs:w-full sm:w-3/4"
          src={founder}
        ></img>
        <div className="flex flex-col justify-between">
          <div className="text-white text-right m-6 font-poppins xxs:text-center sm:text-right">
            <h1 className="text-5xl font-bold">Zhai zixi</h1>
            <h3 className="text-lg font-bold opacity-50">Founder</h3>
          </div>
          <p className="text-white p-6 text-xl text-right xxs:text-center sm:text-right">
          Orangethink has always been at the forefront of technology and design, evolving from traditional industry standards to today’s digital world. We continuously push boundaries, with innovation at the core of our cross-border e-commerce and global content platforms. While technology evolves, our commitment to delivering the best user experience remains unchanged.{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutTeamBigCard
