import React from "react"
import Fade from "react-reveal/Fade"

import about1 from "../../images/about1.webp"

const AboutHeader = () => {
  return (
    <Fade bottom cascade>
      <div className="max-w-7xl mx-auto mt-10 px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-xl">
          <img src={about1} alt="about us" className="w-full h-auto"></img>
        </div>
        <div className="mt-5">
          <span className="text-white text-sm opacity-50">About us</span>
          <h1 className="text-white font-poppins font-semibold leading-snug 
                         text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            We're on a mission to reinvent web, UX, product, and content design—
            by making it visual. If that sounds good to you, you should apply.
          </h1>
        </div>
      </div>
    </Fade>
  )
}

export default AboutHeader
